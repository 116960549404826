<template>
  <div class="page-content">

    <div class="allbox">
      <div class="indexbox">
        <h3>人员基本信息</h3>
        <div class="item-text">
          <div class="name">姓名</div>
          <div class="val" v-text="userInfo.name"></div>
        </div>
        <div class="item-text">
          <div class="name">性别</div>
          <div class="val" v-text="sexMap[userInfo.sex]"></div>
        </div>
        <div class="item-text">
          <div class="name">职级</div>
          <div class="val" v-text="jobGradeMap[userInfo.jobGrade]"></div>
        </div>
        <div class="item-text">
          <div class="name">职务</div>
          <div class="val" v-text="userInfo.jobName"></div>
<!--          <div class="val">-->
<!--            <div v-for="item in userInfo.ljJobsList" :key="item.id">-->
<!--              {{ item.jobName }}-->
<!--            </div>-->
<!--          </div>-->
        </div>
        <div class="item-text">
          <div class="name">方案类型</div>
          <div class="val" v-text="
            Object.keys(userInfo).length > 0 ? userInfo.assessment.name : ''
          "></div>
        </div>

        <!-- <van-cell-group>
        <van-cell title="姓名" :value="userInfo.name" />
        <van-cell title="性别" :value="sexMap[userInfo.sex]" />
        <van-cell title="职级" :value="jobGradeMap[userInfo.jobGrade]" />
        <van-cell title="职务">
          <template #default>
            <div v-for="item in userInfo.ljJobsList" :key="item.id">
              {{ item.jobName }}
            </div>
          </template>
        </van-cell>
        <van-cell
          title="方案类型"
          :value="
            Object.keys(userInfo).length > 0 ? userInfo.assessment.name : ''
          "
        />
      </van-cell-group>
      <div align="center">
        <router-link :to="{ path: '/step', query: { index: 1 } }">
          <van-button round block style="width: 50%"
            >信息无误，下一步</van-button
          >
        </router-link>
      </div> -->
      </div>

      <div class="btnbox">
        <router-link :to="{ path: '/step', query: { index: 1 } }">
          <van-button round block class="submitbtn">信息无误，下一步</van-button>
        </router-link>

        <div class="tip-desc">
          若信息有误，请及时联系部门 领导或监察部门进行调整
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { Row, Col, Icon, Cell, CellGroup, NavBar, Button } from 'vant'
import { mapGetters } from 'vuex'

const sexMap = { 1: '男', 2: '女' }
const jobGradeMap = {
  0: '特级',
  1: '一级',
  2: '二级',
  3: '三级',
  4: '四级',
  5: '五级',
  6: '六级',
  7: '七级',
  8: '八级',
  9: '九级'
}

export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [NavBar.name]: NavBar,
    [Button.name]: Button
  },
  computed: {
    ...mapGetters({
      wechatOpenId: 'wechatOpenId',
      wxInfo: 'wxInfo',
      userInfo: 'userInfo'
    })
  },
  data() {
    return {
      sexMap,
      jobGradeMap
    }
  },
  mounted() {
    console.log(this.wxInfo)
    console.log(this.userInfo)
  },
  methods: {
    onClickLeft() {
      this.$router.back(-1)
    }
  }
}
</script>
<style src="../../static/oa-img/css/user.css"  scoped></style>
<style lang="less" scoped>
</style>
